/*
 *
 * CheckoutStep3Page constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/CheckoutStep3Page/DEFAULT_ACTION',
  GET_ORDER_DRAFT_REQUEST = 'app/CheckoutStep3Page/GET_ORDER_DRAFT_REQUEST',
  GET_ORDER_DRAFT_SUCCESS = 'app/CheckoutStep3Page/GET_ORDER_DRAFT_SUCCESS',
  GET_ORDER_DRAFT_FAILURE = 'app/CheckoutStep3Page/GET_ORDER_DRAFT_FAILURE',
}

export default ActionTypes;
