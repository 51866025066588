import { select, put, call, fork } from 'redux-saga/effects';
import { push, createMatchSelector } from 'connected-react-router';
import { getOrderDraft } from 'utils/apollo';
import { getOrderDraft as getOrderDraftActions } from './actions';
import moment from 'moment';
import { computeCheckoutQuantityAndTotal } from 'containers/CheckoutStep1Page/saga';
import { sortCartSetByProductName } from 'containers/MainLayout/transformCart';

function computeCheckoutDeliveryDate(orderDrafts: any) {
  return orderDrafts.reduce((agg, singleSupplierCart) => {
    const selectedDeliveryStart = moment(singleSupplierCart.deliveryDatetime.start);
    agg[singleSupplierCart.supplier.id] = selectedDeliveryStart.format('DD/MM/YYYY');
    return agg;
  }, {});
}

function computeCheckout(cart: any) {
  return {
    deliveryDate: computeCheckoutDeliveryDate(cart.orderDrafts),
    ...computeCheckoutQuantityAndTotal(cart.orderDrafts),
  };
}

function* initCheckoutStep1Flow() {
  const route = yield select(createMatchSelector('/checkout/step/confirm/:slug'));
  yield put(getOrderDraftActions.request());

  const response = yield call(getOrderDraft, {
    token: route.params.slug,
  });

  if (!response || response.errors) {
    yield put(getOrderDraftActions.failure(response.errors));
    yield put(push('/checkout/step/2'));
  } else {
    yield put(
      getOrderDraftActions.success({
        orderDrafts: sortCartSetByProductName(response.orderDrafts),
        checkout: computeCheckout(response),
      }),
    );
  }
}

export default function* checkoutStep3PageSaga() {
  yield fork(initCheckoutStep1Flow);
}
