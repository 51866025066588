import styled from 'styled-components';
import media from 'utils/mediaStyle';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 6pt;
  background-color: white;
  border: 1px solid #dce0e9;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(4, 15, 15, 0.2);
  ${media.sm`
    background-color: transparent;
    border: none;
    box-shadow: none;
  `};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${media.sm`
    width: 452px;
  `} a, button {
    flex: 1;
    width: 100%;
  }
  a {
    display: block;
  }
`;

export const FooterWrapper = styled.div`

  @media (hover: none) {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #f9fafc;
    width: 100vw;
    padding: 10pt 7pt;
  }
`;
