/*
 *
 * CheckoutStep1Page actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';
import {} from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const getOrderDraft = createAsyncAction(
  ActionTypes.GET_ORDER_DRAFT_REQUEST,
  ActionTypes.GET_ORDER_DRAFT_SUCCESS,
  ActionTypes.GET_ORDER_DRAFT_FAILURE,
)<void, {}, Error>();
