/*
 *
 * CheckoutStep1Page reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  orderDrafts: {},
  loading: false,
  checkout: {
    deliveryNotes: [],
    quantity: {},
    totalOfSupplier: {},
    price: {},
    timeSlot: {},
    deliveryDate: {},
    total: 0,
  },
};

function checkoutStep3PageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.GET_ORDER_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ORDER_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
      };
    default:
      return state;
  }
}

export default checkoutStep3PageReducer;
