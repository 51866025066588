import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Table,
  TableBody as TableBodyStep,
  TableCell as TableCellStep,
  TableHeader,
  TableRow as TableRowStep,
} from './StepItem';

import Label, { ImageLabel } from './Label';
import React from 'react';
import { Viewport } from 'components/ViewportProvider';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import { CartItem, SingleSupplierCart } from 'types/schema';
import {
  ProductsQuantityType,
  TableRowContentDesktop,
  TableRowContentMobile,
  TableRowWithTracking,
} from './OrderTableRow';

const TableBody = styled(TableBodyStep)`
  @media (max-width: 575px) {
    display: block;
  }
`;

const TableCell = styled(TableCellStep)`
  @media (min-width: 575px) {
    ${({ fixwidth }) =>
      fixwidth
        ? `
      width: 230px;
    `
        : ``};
  }
  @media (min-width: 769px) {
    ${({ fixwidth }) =>
      fixwidth
        ? `
      width: 320px;
    `
        : ``};
  }
  @media (max-width: 575px) {
    &:not(.hideOnMobile) {
      display: block;
    }
    &.quantity-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    &.padding-right-less {
      padding-right: 0;
    }
    padding: 5px 10px;
  }
`;

const TableRow = styled(TableRowStep)`
  @media (max-width: 575px) {
    display: block;
  }
`;
interface IOrderTable {
  singleSupplierCart: SingleSupplierCart;
  disabled?: boolean;
  name: string;
  onRemoveAllItem?: (singleSupplierCart: SingleSupplierCart) => void;
  onRemoveItem?: (cartItem: CartItem) => void;
  productsQuantity: ProductsQuantityType;
  shouldTrackCartItem?: boolean;
}

const OrderTable = (props: IOrderTable) => {
  const { shouldTrackCartItem, disabled, name, onRemoveAllItem, productsQuantity, onRemoveItem, singleSupplierCart } =
    props;
  const { supplier } = singleSupplierCart;

  const onRemoveProduct = (item: CartItem) => () => {
    if (item?.product && onRemoveItem) {
      onRemoveItem({
        product: { ...item.product, supplier: supplier },
      });
    }
  };

  if (!singleSupplierCart || !singleSupplierCart?.items) return null;
  const { items } = singleSupplierCart;

  if (!supplier) return null;

  return (
    <Viewport.Consumer>
      {({ width }) => {
        if (width < 575) {
          return (
            <Table>
              <TableBody>
                {items.map((item) => {
                  if (item.product && productsQuantity[item.product.id] > 0) {
                    if (shouldTrackCartItem) {
                      return (
                        <TableRowWithTracking product={item.product} key={`${name}-${item.product.id}`}>
                          <TableRowContentMobile
                            key={`${name}-${item.product.id}`}
                            item={item}
                            supplier={supplier}
                            disabled={disabled}
                            onRemoveProduct={onRemoveProduct}
                            productsQuantity={productsQuantity}
                          />
                        </TableRowWithTracking>
                      );
                    }
                    return (
                      <TableRow key={`${name}-${item.product.id}`}>
                        <TableRowContentMobile
                          item={item}
                          supplier={supplier}
                          disabled={disabled}
                          onRemoveProduct={onRemoveProduct}
                          productsQuantity={productsQuantity}
                        />
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          );
        }
        return (
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  <ImageLabel>
                    <FormattedMessage {...utilsMessages.itemName} />
                  </ImageLabel>
                </TableCell>
                <TableCell colSpan="2" />
                <TableCell>
                  <Label>
                    <FormattedMessage {...utilsMessages.price} />
                  </Label>
                </TableCell>
                <TableCell>
                  <Label>
                    <FormattedMessage {...utilsMessages.quantity} />
                  </Label>
                </TableCell>
                <TableCell>
                  <Label>
                    <FormattedMessage {...utilsMessages.totalInclVAT} />
                  </Label>
                </TableCell>
                {!disabled && (
                  <TableCell plain>
                    <a
                      style={{ color: '#FE5043', whiteSpace: 'nowrap' }}
                      href="javascript:void(0)"
                      onClick={() => onRemoveAllItem && onRemoveAllItem(singleSupplierCart)}
                    >
                      <FormattedMessage {...utilsMessages.deleteAllItems} />
                    </a>
                  </TableCell>
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {items.map((item) => {
                if (item.product && productsQuantity[item.product.id] > 0) {
                  if (shouldTrackCartItem) {
                    return (
                      <TableRowWithTracking product={item.product} key={`${name}-${item.product.id}`}>
                        <TableRowContentDesktop
                          item={item}
                          supplier={supplier}
                          disabled={disabled}
                          onRemoveProduct={onRemoveProduct}
                          productsQuantity={productsQuantity}
                        />
                      </TableRowWithTracking>
                    );
                  }
                  return (
                    <TableRow key={`${name}-${item.product.id}`}>
                      <TableRowContentDesktop
                        item={item}
                        supplier={supplier}
                        disabled={disabled}
                        onRemoveProduct={onRemoveProduct}
                        productsQuantity={productsQuantity}
                      />
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        );
      }}
    </Viewport.Consumer>
  );
};

export default injectIntl(OrderTable);
