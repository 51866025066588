import { isEmpty } from 'lodash';
import { Location, Maybe } from '../types/schema';
export default (location?: Maybe<Location> | undefined) => {
  if (location && !isEmpty(location)) {
    const addressOrder = ['address', 'ward', 'district', 'city'];
    return addressOrder
      .reduce((acc, item) => {
        if (location?.[item]) {
          acc.push(location?.[item]);
        }
        return acc;
      }, [] as string[])
      .join(', ');
  }
  return '....';
};
