/*
 *
 * CheckoutStep1Page constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/CheckoutStep1Page/DEFAULT_ACTION',
  REMOVE_ALL_PRODUCT = 'app/CheckoutStep1Page/REMOVE_ALL_PRODUCT',
}

export default ActionTypes;
