import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the checkoutStep1Page state domain
 */

const selectCheckoutStep3PageDomain = (state: ApplicationRootState) => {
  return state ? state.checkoutStep3Page : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by CheckoutStep1Page
 */

const selectCheckoutStep3Page = () =>
  createSelector(selectCheckoutStep3PageDomain, substate => {
    return substate;
  });

const selectOrderDrafts = () =>
  createSelector(selectCheckoutStep3PageDomain, substate => {
    return substate.orderDrafts;
  });

const selectLoadingOrderDrafts = () =>
  createSelector(selectCheckoutStep3PageDomain, substate => {
    return substate.loading;
  });

const selectCheckout = () =>
  createSelector(selectCheckoutStep3PageDomain, substate => {
    return substate.checkout;
  });

export default selectCheckoutStep3Page;
export { selectCheckoutStep3PageDomain, selectOrderDrafts, selectLoadingOrderDrafts, selectCheckout };
