import { call, put, fork, take, all, select } from 'redux-saga/effects';
import ActionTypes from './constants';
import MainActionTypes from 'containers/MainLayout/constants';
import { refreshCart, updateItemCart } from 'containers/MainLayout/actions';
import { removeCartItemLight, removeCartSupplierLight } from 'utils/apollo';
import { flatMap } from 'lodash';
import { getStore } from 'containers/MainLayout/saga';
import { selectCart } from 'containers/MainLayout/selectors';
import { CartItem } from 'types/schema';
import { KAMEREO_SUPPLIER_ID } from 'utils/constants';
import { setCheckout } from 'containers/CheckoutPage/actions';

export function computeCheckoutQuantity(cartSet: any) {
  return flatMap(cartSet, (singleSupplierCart) => singleSupplierCart.items).reduce((agg, item) => {
    agg[item.product.id] = Number(Number(item.quantity).toFixed(3));
    return agg;
  }, {});
}

export function computeCheckoutSupplierTotals(cartSet: any) {
  return cartSet.reduce((agg, singleSupplierCart) => {
    agg[singleSupplierCart.supplier.id] = singleSupplierCart.total;
    return agg;
  }, {});
}

export function computeCheckoutCartTotal(cartSet: any) {
  return cartSet.reduce((total, singleSupplierCart) => total + singleSupplierCart.total, 0);
}

export function computeCheckoutQuantityAndTotal(cartSet: any) {
  return {
    quantity: computeCheckoutQuantity(cartSet),
    totalOfSupplier: computeCheckoutSupplierTotals(cartSet),
    total: computeCheckoutCartTotal(cartSet),
  };
}

function* initCheckoutStep1Flow() {
  yield call(initCartFlow);
}

function* initCartFlow() {
  const store = yield call(getStore);
  if (!store) {
    return;
  }

  yield put(refreshCart());
  yield take(MainActionTypes.FETCH_CART_SUCCESS);

  const cart = yield select(selectCart());

  if (cart.notAvailableProducts.length > 0) {
    yield all(
      cart.notAvailableProducts.map((item: CartItem) => {
        return call(removeCartItemLight, {
          input: { storeId: store.id, supplierId: KAMEREO_SUPPLIER_ID, productId: item?.product?.id },
        });
      }),
    );
  }
  yield put(setCheckout(computeCheckoutQuantityAndTotal(cart.cartSet)));
}

function* removeAllProductFlow(action) {
  const { payload } = action;
  const store = yield call(getStore);
  if (!store) {
    return;
  }
  const response = yield call(removeCartSupplierLight, {
    storeId: store.id,
    ...payload,
  });
  if (!response.errors) {
    yield put(updateItemCart.success(response.cart));
  }
}

export default function* checkoutStep1PageSaga() {
  yield all(
    [
      [ActionTypes.REMOVE_ALL_PRODUCT, removeAllProductFlow],
      [MainActionTypes.UPDATE_CART_SUCCESS, initCartFlow],
    ].map(([actionName, handler]) =>
      fork(function* () {
        while (true) {
          const action = yield take(actionName as any);
          yield call(handler as any, action as any);
        }
      }),
    ),
  );

  yield fork(initCheckoutStep1Flow);
}
