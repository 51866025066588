import styled from 'styled-components';
import media from 'utils/mediaStyle';

import Value from './Value';

export default styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6pt;
  width: 100%;
  ${media.sm`
    max-width: 300px;
    margin-bottom: 12pt;
  `} ${Value} {
    ${media.sm`
      font-size: 16px;
    `};
  }
`;
