/**
 *
 * CheckoutStep3Page
 *
 */

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import translations from 'translations';
import messages from 'containers/CheckoutPage/messages';

import { checkoutCart, goToStep, setSomethingChanged } from 'containers/CheckoutPage/actions';
import CheckoutStep3 from 'components/Checkout/CheckoutStep3';
import { selectOrderDrafts, selectLoadingOrderDrafts, selectCheckout } from './selectors';
import { push } from 'connected-react-router';
import LoadingIndicator from 'components/LoadingIndicator';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';

import { RootState } from './types';
import { selectIsFreeshipOffer, selectOrderDisabled, selectStore } from 'containers/MainLayout/selectors';
import { Cart, OrderDisabledResponse, OrderDraft, Store } from 'types/schema';
import React from 'react';
import { selectOpenModalSomthingChanged } from 'containers/CheckoutPage/selectors';

interface OwnProps {
  cart: Cart;
}

interface StateProps {
  store: Store;
  checkout: any;
  loading: boolean;
  orderDisabled: OrderDisabledResponse;
  orderDrafts: OrderDraft[];
  openModalSomthingChanged: boolean;
  isFreeshipOffer: boolean;
}

interface DispatchProps {
  onCheckout: () => void;
  onGoPreviousStep: () => void;
  onGoToStep1: (step: number) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const CheckoutStep3Page = (props: Props) => {
  const { loading, onCheckout, onGoPreviousStep, checkout, cart, orderDisabled, store, onGoToStep1, isFreeshipOffer } =
    props;
  if (loading) return <LoadingIndicator />;
  return (
    <>
      <Helmet>
        <title>{translations(messages.headerStep3)}</title>
        <meta name="description" content={`${translations(messages.headerStep3)}`} />
      </Helmet>
      <CheckoutStep3
        loading={loading}
        onCheckout={onCheckout}
        onGoPreviousStep={onGoPreviousStep}
        checkout={checkout}
        cart={cart}
        orderDisabled={orderDisabled.orderDisabled}
        store={store}
        openModalSomthingChanged={props.openModalSomthingChanged}
        onGoToStep1={onGoToStep1}
        isFreeshipOffer={isFreeshipOffer}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  orderDrafts: selectOrderDrafts(),
  checkout: selectCheckout(),
  loading: selectLoadingOrderDrafts(),
  orderDisabled: selectOrderDisabled(),
  store: selectStore(),
  openModalSomthingChanged: selectOpenModalSomthingChanged(),
  isFreeshipOffer: selectIsFreeshipOffer(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onGoPreviousStep: () => dispatch(push('/checkout/step/2')),
    onCheckout: () => dispatch(checkoutCart()),
    onGoToStep1: () => {
      dispatch(setSomethingChanged());
      dispatch(goToStep(1));
    },
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { orderDrafts, checkout, loading, store, openModalSomthingChanged, isFreeshipOffer } = stateProps;
  return {
    cart: {
      cartSet: orderDrafts,
    },
    checkout: checkout,
    loading: loading,
    ...dispatchProps,
    ...ownProps,
    orderDisabled: stateProps.orderDisabled,
    store: store,
    openModalSomthingChanged: openModalSomthingChanged,
    isFreeshipOffer: isFreeshipOffer,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'checkoutStep3Page',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'checkoutStep3Page', saga: saga });

export default compose(withReducer, withSaga, withConnect)(CheckoutStep3Page);
