import styled from 'styled-components';
import media from 'utils/mediaStyle';

const Label = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  text-transform: capitalize;
  white-space: nowrap;
  ${media.lg`
    min-width: 112pt;
  `};
`;

export default Label;

export const ImageLabel = styled(Label)`
  ${media.lg`
    min-width: auto;
  `};
`;
