import styled from 'styled-components';
import media from 'utils/mediaStyle';

export default styled.div<any>`
  flex: ${({ span }) => span || ''};
  padding-right: 4px;
  padding-left: 4px;
  &.flex-initial {
    flex: initial;
  }
  ${({ space }) =>
    space &&
    `
    margin-top: 10pt;
    padding-top: 10pt;
  `} button {
    font-size: 14px;
  }
  ${media.sm`
    :last-child {
      margin-top: 0;
      padding-top: 0;
    }
  `};
`;
