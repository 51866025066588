import { TableCell as TableCellStep, TableRow as TableRowStep } from './StepItem';
import Value, { Description } from './Value';

import LazyImage from 'components/Image/LazyImage';
import QuantityInput from 'containers/QuantityInput';
import React, { FC } from 'react';
import styled from 'styled-components';
import { CartItem, Product, Supplier, TrackingAddToCartAddFromType } from 'types/schema';
import { allowDecimalQuantity } from 'utils/allowDecimalQuantity';
import generateProductSlug from 'utils/generateProductSlug';
import BButton from '../Button/Button';
import Money from '../Money/Money';
import Label from './Label';
import noImage from './noIMG.svg';
import { DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const TableCell = styled(TableCellStep)`
  @media (min-width: 575px) {
    ${({ fixwidth }) =>
      fixwidth
        ? `
      width: 230px;
    `
        : ``};
  }
  @media (min-width: 769px) {
    ${({ fixwidth }) =>
      fixwidth
        ? `
      width: 320px;
    `
        : ``};
  }
  @media (max-width: 575px) {
    &:not(.hideOnMobile) {
      display: block;
    }
    &.quantity-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    &.padding-right-less {
      padding-right: 0;
    }
    padding: 5px 10px;
  }
`;

const TableRow = styled(TableRowStep)`
  @media (max-width: 575px) {
    display: block;
  }
`;

const Button = styled(BButton)`
  height: auto;
  width: auto;
  color: #fe5043;
  font-size: 14px;
  &:visited,
  &:hover {
    path {
      fill: #fe5043;
    }
  }
`;

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  &.margin-right-10 {
    margin-right: 10px;
  }
`;

const NoWrapText = styled.span`
  white-space: nowrap;
`;

const FlexContainer = styled.div`
  width: 180px;
  min-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Note = styled.p`
  color: #8c8c8c;
`;

const TableCellImageMb = styled.div<any>`
  display: flex;
`;

const ImageWrapper = styled.div<any>`
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 4px;
  display: inline-block;
`;

const TableCellCtMb = styled.div<any>`
  flex: 1;
  padding-left: 8px;
`;

export type ProductsQuantityType = {
  [key: string]: number;
};
interface RowProps {
  item: CartItem;
  supplier: Supplier;
  disabled?: boolean;
  onRemoveProduct: (item: CartItem) => void;
  productsQuantity: ProductsQuantityType;
}

interface ITableRowWithTracking {
  product: Product;
}
export const TableRowWithTracking: FC<ITableRowWithTracking> = (props) => {
  const { children } = props;

  return <TableRow>{children}</TableRow>;
};

export const TableRowContentMobile: FC<RowProps> = (props) => {
  const { item, disabled, onRemoveProduct, productsQuantity } = props;
  if (!item?.product) return null;

  const { id, imageUrl, note, price, uom, uomLocal, vat, name, supplierInternalCode } = item.product;
  const lang = localStorage.getItem('lang') || 'vi';
  return (
    <>
      <TableCell>
        <TableCellImageMb>
          <ImageWrapper>
            <ItemImage noImage={!imageUrl} src={imageUrl || noImage} alt={name} />
          </ImageWrapper>
          <TableCellCtMb>
            <Value>
              <a href={`/products/${generateProductSlug(name, supplierInternalCode, lang)}`} target="_blank">
                <Description>{name}</Description>
              </a>
            </Value>
            {note && <Note>{note}</Note>}
          </TableCellCtMb>
        </TableCellImageMb>
      </TableCell>
      {!disabled && ( // checkout step 1
        <TableCell className="quantity-row">
          <div>
            <div>
              <Money>{price}</Money>
              <NoWrapText>/{uomLocal} </NoWrapText>
            </div>
            <div>
              <Label>({vat}% VAT)</Label>
            </div>
          </div>
          <FlexContainer>
            <Button
              type="secondary"
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              style={{ color: 'rgba(0,0,0,0.54)' }}
              ghost
              onClick={onRemoveProduct(item)}
            />
            <QuantityInput
              product={item.product}
              size="small"
              min={0.01}
              allowDecimal={allowDecimalQuantity(uom)}
              addFrom={TrackingAddToCartAddFromType.CartProduct}
            />
          </FlexContainer>
        </TableCell>
      )}
      {disabled && ( // checkout step 3
        <TableCell className="quantity-row">
          <div>
            <div>
              <StyledSpan className="margin-right-10">
                {item.quantity} {uomLocal}
              </StyledSpan>
            </div>
            <div>
              <Money>{price}</Money>
              <NoWrapText>/{uomLocal} </NoWrapText>
            </div>
          </div>
          <div>
            <Money>{price * productsQuantity[id]}</Money>
            <Label>({vat}% VAT)</Label>
          </div>
        </TableCell>
      )}
    </>
  );
};

const ItemImage = styled(({ noImage, ...rest }) => <LazyImage {...rest} />)<any>`
  width: 70px;
  height: 70px;
  object-fit: ${({ noImage }) => (noImage ? 'contain' : 'cover')};
  ${({ noImage }) => (noImage ? `opacity: 0.3;` : '')};
`;

export const TableRowContentDesktop: FC<RowProps> = (props) => {
  const { item, disabled, onRemoveProduct, productsQuantity } = props;
  if (!item.product) return null;

  const { id, imageUrl, note, price, uom, uomLocal, vat, name, supplierInternalCode } = item.product;
  const lang = localStorage.getItem('lang') || 'vi';
  return (
    <>
      <TableCell>
        <ImageWrapper>
          <ItemImage noImage={!imageUrl} src={imageUrl || noImage} alt={name} />
        </ImageWrapper>
      </TableCell>
      <TableCell colSpan="2" fixwidth>
        <Link to={`/products/${generateProductSlug(name, supplierInternalCode, lang)}`}>
          <Value>
            <Description>{name}</Description>
          </Value>
        </Link>
        {note && <Note>{note}</Note>}
      </TableCell>
      <TableCell className="hideOnMobile">
        <Money>{price}</Money>
        <NoWrapText>/{uomLocal} </NoWrapText>
      </TableCell>
      {disabled && (
        <TableCell className="hideOnMobile">
          <span>
            {item.quantity} {uomLocal}
          </span>
        </TableCell>
      )}
      {!disabled && (
        <TableCell className="hideOnMobile">
          <QuantityInput
            product={item.product}
            size="small"
            min={0.01}
            allowDecimal={allowDecimalQuantity(uom)}
            addFrom={TrackingAddToCartAddFromType.CartProduct}
          />
        </TableCell>
      )}
      <TableCell className="hideOnMobile">
        <Money>{price * productsQuantity[id]}</Money>
        &nbsp;
        <NoWrapText>({vat}% VAT)</NoWrapText>
      </TableCell>
      {!disabled && (
        <TableCell className="hideOnMobile">
          <Button
            type="secondary"
            shape="circle"
            icon={<DeleteOutlined />}
            size="small"
            style={{ color: 'rgba(0,0,0,0.54)' }}
            ghost
            onClick={onRemoveProduct(item)}
          />
        </TableCell>
      )}
    </>
  );
};
