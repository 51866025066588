import styled from 'styled-components';

const Value = styled.div<any>`
  display: flex;
  align-items: center;
  ${({ color }) => (color ? `color: ${color};` : 'color: rgba(0, 0, 0, 0.87);')};
  a {
    color: rgba(0, 0, 0, 0.87);
  }
  &.justify-content-between {
    justify-content: space-between;
  }
  ${({ nowrap }) => (nowrap ? 'white-space: nowrap' : '')};
`;
export const SupplierName = styled(Value)`
  font-size: 14px;
  white-space: normal;
`;
export const Description = styled.div`
  white-space: normal;
`;

export default Value;
