import * as React from 'react';
import money from '../../utils/formatMoney';
import styled from 'styled-components';

const Money = styled.span<any>`
  text-transform: initial;
  white-space: nowrap;
  ${({ type }) => {
    if (type === 'old') {
      return `
        color: rgba(0, 0, 0, 0.54);
        text-decoration: line-through;
    `;
    }
    if (type === 'new') {
      return `
        color: red;
      `;
    }
    return ``;
  }};
`;

interface MoneyProps {
  type?: 'old' | 'new' | 'normal';
  children: number;
}

export default function(props: MoneyProps) {
  return <Money {...props}>{money(props.children || 0)}đ</Money>;
}
